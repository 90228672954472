// @ts-ignore
import axios from 'axios';

// importing checkout module
import {
    CompleteTransaction
} from '../checkout';

import {
    TOASTER_ERROR_BG_COLOR
} from '../../../constants';

import PaymentGatewayInfo from '../interfaces/payment-gateway-info.interface';
import SnapCallback from '../interfaces/midtrans/snap-callback.interface';


// url
const PAY_WITH_MIDTRANS_URL = '/umbraco/surface/paymentgateway/paywithmidtrans';
const SAVE_PAYMENT_GATEWAY_INFO_URL = '/umbraco/surface/paymentgateway/savepaymentgatewayinfo';


export function PayWithMidtrans(directDebit: boolean = false) {
    // if not direct debit
    if (!directDebit) {
        // @ts-ignore
        return axios.get(PAY_WITH_MIDTRANS_URL);
    }
    else {
        // @ts-ignore
        return axios.get(PAY_WITH_MIDTRANS_URL + '?isDirectDebit=' + directDebit)
    }
}

export function SavePaymentGatewayInfo(info: PaymentGatewayInfo) {
    // @ts-ignore
    return axios.post(SAVE_PAYMENT_GATEWAY_INFO_URL, info);
}

export function SnapMidtransModal(token: string, callbacks: SnapCallback = null) {
    // @ts-ignore
    if (window.snap != undefined) {
        // @ts-ignore
        window.snap.pay(token, {
            onSuccess: function (result: any) {
                if (callbacks.successCallback != null)
                    callbacks.successCallback(result);
            },
            onPending: function (result: any) {
                if (callbacks.pendingCallback != null)
                    callbacks.pendingCallback(result);
            },
            onError: function (result: any) {
                if (callbacks.errorCallback != null)
                    callbacks.errorCallback(result);
            },
            onClose: function (result: any) {
                if (callbacks.closeCallback != null)
                    callbacks.closeCallback(result);
            }
        });
    }
}



function ParseMidtransSnapResponse(response: any) {
    // get payment type
    const paymentType = response['payment_type'];

    // get transaction status
    const transactionStatus = response['transaction_status'];

    // get amount
    const amount = response['gross_amount'];

    // get virtual account
    let va = undefined;

    if (response['va_numbers'] != undefined) {
        va = response['va_numbers'][0]
    }
    else if (response['permata_va_number']) {
        va = {
            bank: 'permata',
            va_number: response['permata_va_number']
        }
    }

    // get pdf guid
    let pdfUrl = undefined;

    if (response['pdf_url']) {
        pdfUrl = response['pdf_url'];
    }

    // query string to return
    let qs = `paymentType=${paymentType}&transactionStatus=${transactionStatus}&amount=${amount}`

    if (va) {
        qs += `&va_bank=${va['bank']}`;
        qs += `&va_number=${va['va_number']}`;
    }

    if (pdfUrl) {
        qs += `&pdfUrl=${pdfUrl}`;
    }

    return qs;
}