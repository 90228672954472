enum PaymentGatewayMethod {
    Unknown,
    Card,
    VirtualAccount,
    KlikBCA,
    Gopay,
    OverTheCounter,
    BCAKlikPay,
    CIMB_Clicks,
    DanamonOnline,
    BRIEPay,
    Akulaku,
    PayPal
}

export default PaymentGatewayMethod;