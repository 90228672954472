// @ts-ignore
import axios from 'axios';

import PaypalRenderParam from '../interfaces/paypal/paypal-render-param';

class paypalClass {
    private _payWithPaypalUrl = '/umbraco/surface/paymentgateway/paywithpaypal';

    payWithPaypal() {
        // @ts-ignore
        return axios.get(this._payWithPaypalUrl);
    }

    // IMPORTANT
    // requires paypal script
    renderPaypalButton(paypalRender: PaypalRenderParam) {
        // @ts-ignore
        paypal.Buttons({
            createOrder: paypalRender.createOrderCallback,
            onApprove: paypalRender.onApproveCallback
        }).render(paypalRender.htmlSelector);
    }
}

// instantiate
const Paypal = new paypalClass();

// default export
export default Paypal;