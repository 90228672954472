﻿'use strict';

//import 'bootstrap/dist/js/bootstrap.bundle';

import axios from 'axios';
import { TOASTER_ERROR_BG_COLOR } from '../../../constants';

// constants
const GET_URL = (productId, promoId) => `/umbraco/surface/coupon/getvariantsforfreeitem?productId=${productId}&couponId=${promoId}`;

const POST_URL = '/umbraco/surface/coupon/changefreeitem';

const $CHANGE_FREE_ITEM = () => $('[data-action="change-free-item"]');

const $CHANGE_FREE_ITEM_CHOICES_CONTAINER = () => $('[data-identity="freeitem-choices-container"]');

const $MODAL = () => $('[data-identity="modal-change-freeitem"]');

const $MODAL_LOADER = () => $('[data-identity="change-freeitem-loader"]');

const $SUBMIT_BUTTON = () => $('[data-identity="change-freeitem-submit-button"]');

const FREE_ITEM_ELEMENT_STRING = (variant) => {
    // build the variant string for display
    let variantString = '(';

    if (variant.Option1 != null && variant.Option1 !== '') {

        variantString += variant.Option1 + '/';

    }

    if (variant.Option2 != null && variant.Option2 !== '') {

        variantString += variant.Option2 + '/';

    }

    if (variant.Option3 != null && variant.Option3 !== '') {

        variantString += variant.Option3 + '/';

    }

    variantString = variantString.substring(0, variantString.length - 1) + ')';

    return `<div class="free-item">
                <input id="free-item-${variant.ID}" type="radio" name="free-item" value="${variant.ID}" />
                <label for="free-item-${variant.ID}">
                    ${variantString}
                    <span class="circle">
                        <span class="inner-circle"></span>
                    </span>
                </label>
            </div>`;
}


// get the available variants
function getAvailableFreeVariants(productId, promoId) {
    return axios.get(GET_URL(productId, promoId));
}


// submit free item
function submitFreeItem(cartItemId, variantId) {
    return axios.post(POST_URL, { cartItemId, variantId });
}


// empty free item container
function emptyContainerView() {
    $CHANGE_FREE_ITEM_CHOICES_CONTAINER()
        .empty();
}


// attaching free items to view
function attachFreeItemToView(items) {
    items.forEach(v => {

        const elemString = FREE_ITEM_ELEMENT_STRING(v);

        $CHANGE_FREE_ITEM_CHOICES_CONTAINER()
            .append(elemString);

    });
}


// change free item handler
function changeFreeItem() {

    // get data
    let cartItemId = $(this).data('cartItemId');
    let productId = $(this).data('productId');
    let promoId = $(this).data('couponId');

    $SUBMIT_BUTTON()
        .data('cartItemId', cartItemId);

    emptyContainerView();

    $MODAL().modal('show');

    getAvailableFreeVariants(productId, promoId)
        .then(res => {

            console.log(res);

            $MODAL_LOADER()
                .addClass('hidden');

            attachFreeItemToView(res.data);

        })
        .catch(err => {

            console.error(err);

            Toaster({
                text: err.response.statusText,
                position: 'right',
                gravity: 'bottom',
                duration: 5000,
                backgroundColor: TOASTER_ERROR_BG_COLOR
            });

        });
}


// submit button handler
function submitButtonHandler() {

    let cartItemId = $(this).data('cartItemId');

    let variantId = $('[name="free-item"]:checked').val();

    console.log(variantId);

    if (variantId) {

        submitFreeItem(cartItemId, variantId)
            .then(() => {

                window.location.reload();

            })
            .catch((err) => {

                console.error(err.response.status);

                Toaster({
                    text: err.response.statusText,
                    gravity: 'bottom',
                    position: 'right',
                    duration: 5000,
                    backgroundColor: TOASTER_ERROR_BG_COLOR
                });

            })

    }
    else {

        Toaster({
            text: 'Please choose your variant!',
            gravity: 'bottom',
            position: 'right',
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR
        });

    }

}


if ($CHANGE_FREE_ITEM().length) {
    $CHANGE_FREE_ITEM().on('click', changeFreeItem);

    $SUBMIT_BUTTON().on('click', submitButtonHandler);
}