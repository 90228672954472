﻿'use strict';


import {
    TOASTER_SUCCESS_BG_COLOR,
    TOASTER_ERROR_BG_COLOR
} from '../../../constants';

import axios from 'axios';


const ADD_NOTIFY_URL = '/umbraco/surface/cart/notifyme';

const $NOTIFY_INPUT = () => $('[data-identity="notify-input"]');

const $NOTIFY_SUBMIT_BUTTON = () => $('[data-action="save-notify"]');

// coupon code
export function SubmitNotify(notify) {
    console.log('submit notify');
    try {

        axios.put(ADD_NOTIFY_URL, notify)
            .then(res => {

                Notify("We will send a notification when the stock is available", 'success');

                window.location.reload();
            });

        //let jqxhr = $.post(ADD_NOTIFY_URL, { notify });

        //jqxhr.then((res) => {

        //    window.location.reload();

        //}).always(() => {

        //    if (jqxhr.status === 400) {

        //        Toaster({
        //            text: jqxhr.statusText,
        //            duration: 5000,
        //            gravity: 'bottom',
        //            position: 'right',
        //            backgroundColor: TOASTER_ERROR_BG_COLOR
        //        });

        //    }

        //});

    } catch (e) {
        //

        Toaster({
            text: e.statusText,
            duration: 5000,
            gravity: 'bottom',
            position: 'right',
            backgroundColor: TOASTER_ERROR_BG_COLOR
        });
    }

}

if ($NOTIFY_SUBMIT_BUTTON().length) {


    $NOTIFY_SUBMIT_BUTTON().on('click', function () {

        if ($NOTIFY_INPUT().val() !== '') {

            var notify =
            {
                ProductId: $(this).data('product-id'),
                Email: $NOTIFY_INPUT().val()
            };

            SubmitNotify(notify);

        }
        else {

            Toaster({
                text: 'Please fill email!',
                duration: 5000,
                gravity: 'bottom',
                position: 'right',
                backgroundColor: TOASTER_ERROR_BG_COLOR
            });

        }

    });

}