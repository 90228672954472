﻿import axios from 'axios';

// importing constants
import {
    COMPLETE_TRANSACTION_URL
} from './constants';


export function CompleteTransaction(invoiceNumber = null) {
    return axios.post(COMPLETE_TRANSACTION_URL, { invoiceNumber });
}
