﻿'use strict';

import { NATIV_DARK_SPINNER, TOASTER_ERROR_BG_COLOR } from "../../../constants";

const ADD_COUPON_URL = '/umbraco/surface/coupon/addcouponcode';

const $COUPON_CODE_INPUT = () => $('[data-identity="coupon-code-input"]');

const $COUPON_CODE_SUBMIT_BUTTON = () => $('[data-action="submit-coupon-code"]');

// coupon code
function SubmitCouponCode(couponCode) {
    console.log('submit coupon code');
    try {

        let jqxhr = $.post(ADD_COUPON_URL, { couponCode });

        jqxhr.then((res) => {

            window.location.reload();

        }).always(() => {

            EnablingCouponSubmitCode();

            if (jqxhr.status == 400) {

                Toaster({
                    text: jqxhr.statusText,
                    duration: 5000,
                    gravity: 'bottom',
                    position: 'right',
                    backgroundColor: TOASTER_ERROR_BG_COLOR
                });

            }
            else if (jqxhr.status == 500) {

                Toaster({
                    text: jqxhr.statusText,
                    duration: 5000,
                    gravity: 'bottom',
                    position: 'right',
                    backgroundColor: TOASTER_ERROR_BG_COLOR
                });

            }

        });

    } catch (e) {

    }

}

function EnablingCouponSubmitCode() {

    $COUPON_CODE_SUBMIT_BUTTON()
        .html('Submit')
        .removeClass('disabled')
        .prop('disabled', false);

}

function DisablingCouponSubmitCode() {

    $COUPON_CODE_SUBMIT_BUTTON()
        .html(NATIV_DARK_SPINNER)
        .addClass('disabled')
        .prop('disabled', true);

}

if ($COUPON_CODE_SUBMIT_BUTTON().length && $COUPON_CODE_INPUT().length) {

    $COUPON_CODE_INPUT().on('keydown', function (e) {

        if (e.keyCode == '13') {

            DisablingCouponSubmitCode();

            if ($(this).val() != undefined && $(this).val() != null && $(this).val() != '') {

                SubmitCouponCode($(this).val());

            }
            else {

                EnablingCouponSubmitCode();

                Toaster({
                    text: 'Please fill in the coupon code!',
                    duration: 5000,
                    gravity: 'bottom',
                    position: 'right',
                    backgroundColor: TOASTER_ERROR_BG_COLOR
                });

            }

        }

    });

    $COUPON_CODE_SUBMIT_BUTTON().on('click', function () {

        if ($COUPON_CODE_INPUT().val() != '' && $COUPON_CODE_INPUT().val() != undefined) {

            DisablingCouponSubmitCode();

            SubmitCouponCode($COUPON_CODE_INPUT().val());

        }
        else {

            EnablingCouponSubmitCode();

            Toaster({
                text: 'Please fill in the coupon code!',
                duration: 5000,
                gravity: 'bottom',
                position: 'right',
                backgroundColor: TOASTER_ERROR_BG_COLOR
            });

        }

    });

}