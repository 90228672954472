﻿'use strict';

// toaster color
import {
    TOASTER_ERROR_BG_COLOR,
    TOASTER_SUCCESS_BG_COLOR
} from '../../constants';

import {
    $MASTER_LOADER
}from '../ecommerce/constants'

const $CONFIRM_PAYMENT_FORM = $('#confirm-payment-form');

const $CONFIRM_PAYMENT_SUBMIT_BUTTON = $('[data-identity="payment-confirmation-submit-button"]');
const $CONFIRM_PAYMENT_SUBMIT_BUTTON_NAVI = $('[data-identity="payment-confirmation-submit-button-navi"]');

const $CONFIRM_PAYMENT_INVOICE_NUMBER = $('[data-identity="payment-confirmation-invoice-number"]');

if ($CONFIRM_PAYMENT_SUBMIT_BUTTON_NAVI.length) {
    $CONFIRM_PAYMENT_SUBMIT_BUTTON_NAVI.click(function () {
        console.log('confirm payment');

        $MASTER_LOADER.addClass('active');

        var formdata = new FormData(); //FormData object
        var fileInput = document.getElementById('files');
        //Iterating through each files selected in fileInput
        for (var i = 0; i < fileInput.files.length; i++) {
            //Appending each file to FormData object
            formdata.append(fileInput.files[i].name, fileInput.files[i]);
        }

        //Creating an XMLHttpRequest and sending
        var xhr = new XMLHttpRequest();
        xhr.open('POST', '/umbraco/surface/Member/Upload');
        xhr.send(formdata);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                $('#ImageId').val(xhr.responseText);
                $MASTER_LOADER.removeClass('active');
                $CONFIRM_PAYMENT_SUBMIT_BUTTON.click();
            }
        };
    });

}

function OnBeginConfirmPayment() {

    $CONFIRM_PAYMENT_SUBMIT_BUTTON
        .addClass('disabled')
        .prop('disabled', true);
}

window.OnBeginConfirmPayment = OnBeginConfirmPayment;

function OnSuccessConfirmPayment(res) {

    $CONFIRM_PAYMENT_FORM
        .trigger('reset');

    Toaster({
        text: 'You have been successfully confirmed your payment!',
        duration: 5000,
        backgroundColor: TOASTER_SUCCESS_BG_COLOR,
        gravity: 'bottom',
        position: 'right'
    });

    $CONFIRM_PAYMENT_INVOICE_NUMBER.find(`[value="${res.id}"]`).remove();
}

window.OnSuccessConfirmPayment = OnSuccessConfirmPayment;

function OnFailureConfirmPayment(err) {
    $CONFIRM_PAYMENT_SUBMIT_BUTTON
        .removeClass('disabled')
        .prop('disabled', false);

    if (err.status === 400) {
        Toaster({
            text: err.statusText,
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR,
            gravity: 'bottom',
            position: 'right'
        });
    }
    else {
        Toaster({
            text: 'There has been an error confirming your payment!',
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR,
            gravity: 'bottom',
            position: 'right'
        });
    }
}

window.OnFailureConfirmPayment = OnFailureConfirmPayment;