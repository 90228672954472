import * as UTILS from './utils';

// @ts-ignore
import * as _ from 'lodash';

import {
    TOASTER_ERROR_BG_COLOR,
    NATIVE_SPINNER
} from '../../constants';

import {
    SUCCESS_ADD_MESSAGE,
    $DELIVERY_COURIER_COST_INPUT,
    $DELIVERY_COURIER_NAME_INPUT,
    $DELIVERY_COURIER_SERVICE_INPUT,
    $DELIVERY_COURIER_RADIO,
    $DELIVERY_COURIER_SUBMIT_BUTTON,
    DELIVERY_COURIER_ERROR_MESSAGE,
    $PAYMENT_RADIO,
    $PAYMENT_BANK_INPUT,
    $PAYMENT_TYPE_INPUT,
    $PAYMENT_AND_NOTE_SUBMIT_BUTTON,
    PAYMENT_AND_NOTE_ERROR_MESSAGE,
    $COMPLETE_TRANSACTION_BUTTON,
    $PAY_WITH_MIDTRANS_BUTTON,
    $CART_QUANTITY_ELEMENTS,
    $SIDECART_ITEM_CONTAINER,
    $CART_ITEM_DELETE_ACTION_ELEMENT,
    $CART_ITEM_QUANTITY_INPUT,
    IsVariantAvailable,
    COMPLETE_TRANSACTION_URL,
    GET_TRANSACTION_URL,
    $PAY_WITH_PAYPAL_BUTTON,
    $CART_ITEMS,
    $MASTER_LOADER

} from './constants';

import {
    CalculateCart,
    DeleteCartItem,
    UpdateCartItem,
    AddToCart
} from './cart';

import {
    AjaxRequestData
} from '../../global-function';

// messages
const FAIL_ADD_MESSAGE = 'There has been an error adding your item to cart. Please contact our administrator!';
const FAIL_REMOVE_CART_ITEM_MESSAGE = 'Item has been failed to be deleted from cart!';
const STOCK_EMPTY_VARIANT_MESSAGE = 'Sorry this product is out of stock!';
const CHOOSE_VARIANT_MESSAGE = 'Please choose a variant!';

/*
 * ==============================
 * Item Quantity Changes
 * ==============================
 *
 * Item quantity
 *
 */
let itemQuantity = 1;

/*
 * Elements for adding quantity.
 * Could only be button elements.
 *
 */
const $ADD_QUANTITY_ELEMENTS = $('button[data-action="bw-add-cart-item-quantity"]');

/*
 * Elements for reducing quantity.
 * Could only be button elements.
 *
 */
const $REDUCE_QUANTITY_ELEMENTS = $('button[data-action="bw-reduce-cart-item-quantity"]');

/*
 * Element for containing the quantity number.
 *
 */
const $QUANTITY_CONTAINER_ELEMENTS = $('[data-action="bw-cart-item-quantity"]');

const $SHIPPING_ADDRESS_SUBMIT_BUTTON = $('[data-action="submit-shipping-address"]');

/*
 * Adding quantity
 *
 */
function AddQuantity(newQuantity) {
    if (newQuantity && newQuantity >= 0) {
        itemQuantity = newQuantity;
    }
    else {
        itemQuantity++;
    }
}

/*
 * Reducing quantity
 *
 */
function ReduceQuantity(newQuantity) {
    if (newQuantity && newQuantity >= 0) {
        itemQuantity = newQuantity;
    }
    else {
        itemQuantity--;

        if (itemQuantity < 0) {
            itemQuantity = 0;
        }
    }
}

/*
 * Display the quantity number
 *
 */
function DisplayQuantity($elems) {
    $elems.each(function () {
        let $self = $(this);

        if ($self.is('input')) {
            $self.val(itemQuantity);
        }
        else {
            $self.text(itemQuantity);
        }
    });
}

if ($ADD_QUANTITY_ELEMENTS) {
    $ADD_QUANTITY_ELEMENTS.on('click', function () {
        let $self = $(this);

        // get the exact quantity
        let quantity = $self.data('quantity');

        AddQuantity(quantity);

        if ($QUANTITY_CONTAINER_ELEMENTS) {
            DisplayQuantity($QUANTITY_CONTAINER_ELEMENTS);
        }
    });
}

if ($REDUCE_QUANTITY_ELEMENTS) {
    $REDUCE_QUANTITY_ELEMENTS.on('click', function () {
        let $self = $(this);

        // get the exact quantity
        let quantity = $self.data('quantity');

        ReduceQuantity(quantity);

        if ($QUANTITY_CONTAINER_ELEMENTS) {
            DisplayQuantity($QUANTITY_CONTAINER_ELEMENTS);
        }
    });
}

/*
 * ==============================
 * Item Quantity Changes .\END
 * ==============================
 *
 */

/*
 * ==============================
 * Add To Cart
 * ==============================
 *
 */

/*
 * Url endpoint for adding item
 * to cart.
 *
 */
const ALL_VARIANTS_URL = '/umbraco/surface/product/getvariants';

let productVariants = [];

let currentlySelectedVariants = [];

let chosenVariant = undefined;

/*
 * Add to cart elements.
 * Could only be button elements.
 *
 */
const $ADD_TO_CART_ELEMENTS = $('button[data-action="bw-add-cart"]');

/*
 * Element that contain current
 * selected variant in product
 * detail
 *
 */
const $ADD_TO_CART_VARIANT_ELEMENTS = $('[data-action="bw-cart-item-variant"]');

/*
 * Elements that contains
 * first options of variants
 *
 */
const $VARIANT_OPTION_1_ELEMENTS = $('input[type="radio"][data-action="bw-cart-item-option1"]');
const $VARIANT_OPTION_1_ELEMENTS_WITH_OPT = $('input[type="radio"][data-action="bw-cart-item-option1"]');
const $VARIANT_OPTION_1_SELECT_ELEMENTS = $('select[data-action="bw-cart-item-option1"]');

let option1 = undefined;

/*
 * Elements that contains
 * second options of variants
 *
 */
const $VARIANT_OPTION_2_ELEMENTS = $('input[type="radio"][data-action="bw-cart-item-option2"]');
const $VARIANT_OPTION_2_ELEMENTS_WITH_OPT = (opt) => $(`input[type="radio"][data-action="bw-cart-item-option2"][value="${opt}"]`);
const $VARIANT_OPTION_2_SELECT_ELEMENTS = $('select[data-action="bw-cart-item-option2"]');

let option2 = undefined;

/*
 * Elements that contains
 * third options of variants
 *
 */
const $VARIANT_OPTION_3_ELEMENTS = $('input[type="radio"][data-action="bw-cart-item-option3"]');
const $VARIANT_OPTION_3_ELEMENTS_WITH_OPT = (opt) => $(`input[type="radio"][data-action="bw-cart-item-option3"][value="${opt}"]`);
const $VARIANT_OPTION_3_SELECT_ELEMENTS = $('select[data-action="bw-cart-item-option3"]');

let option3 = undefined;

// options length
let optionOneLength = 0;
let optionTwoLength = 0;
let optionThreeLength = 0;

let contentBefore = undefined;

function PopulateOptionsLength(res: []) {
    optionOneLength = _.uniqBy(res, 'Option1').length;
    if (optionOneLength <= 1) {
        option1 = productVariants[0].Option1;
    }

    optionTwoLength = _.uniqBy(res, 'Option2').length;
    if (optionTwoLength <= 1) {
        option2 = productVariants[0].Option2;
    }

    optionThreeLength = _.uniqBy(res, 'Option3').length;
    if (optionThreeLength <= 1) {
        option3 = productVariants[0].Option3;
    }
}

function EnableAllVariantOptions() {

    $VARIANT_OPTION_1_ELEMENTS.removeClass('disabled').prop('disabled', false);

    $VARIANT_OPTION_2_ELEMENTS.removeClass('disabled').prop('disabled', false);

    $VARIANT_OPTION_3_ELEMENTS.removeClass('disabled').prop('disabled', false);

}

function DisableAddToCartElement() {
    if (!$ADD_TO_CART_ELEMENTS.hasClass('disabled')) {
        $ADD_TO_CART_ELEMENTS
            .addClass('disabled');
    }

}

function EnableAddToCartElement() {
    if ($ADD_TO_CART_ELEMENTS.hasClass('disabled')) {
        $ADD_TO_CART_ELEMENTS
            .removeClass('disabled');
    }
}

function LoadSpinnerAddToCartElement() {

    if (($ADD_TO_CART_ELEMENTS.hasClass('disabled'))) {

        // save the content before
        // changed with spinner
        contentBefore = $ADD_TO_CART_ELEMENTS.html();

        // insert spinner
        $ADD_TO_CART_ELEMENTS.html(NATIVE_SPINNER);
    }

}

function UnloadSpinnerAddToCartElement() {

    // insert the content before
    $ADD_TO_CART_ELEMENTS.html(contentBefore);

    // clear the content before
    contentBefore = undefined;

}

function ResetChosenVariant() {
    chosenVariant = undefined;
}

function ResetChoices() {
    $(`label.selected`).removeClass('selected');

    // reset radios
    $VARIANT_OPTION_1_ELEMENTS
        .removeClass('selected')
        .prop('checked', false);

    $VARIANT_OPTION_2_ELEMENTS
        .removeClass('selected')
        .prop('checked', false);

    $VARIANT_OPTION_3_ELEMENTS
        .removeClass('selected')
        .prop('checked', false);

    // reset select
    $VARIANT_OPTION_1_SELECT_ELEMENTS
        .val('');
    $VARIANT_OPTION_2_SELECT_ELEMENTS
        .val('');
    $VARIANT_OPTION_3_SELECT_ELEMENTS
        .val('');

    // reset options chosen
    if (optionOneLength > 1) {
        option1 = undefined;
    }

    if (optionTwoLength > 1) {
        option2 = undefined;
    }

    if (optionThreeLength > 1) {
        option3 = undefined;
    }

    // reset selected variant
    ResetChosenVariant();
}

function ResetOption(option: string) {
    if (option.toLowerCase() == 'option1') {
        option1 = undefined;
    }
    else if (option.toLowerCase() == 'option2') {
        option2 = undefined;
    }
    else if (option.toLowerCase() == 'option3') {
        option3 = undefined;
    }
}

function printOption() {
    console.table([option1, option2, option3]);
}

if ($ADD_TO_CART_ELEMENTS.length) {
    let productId = $ADD_TO_CART_ELEMENTS.first().data('productId');

    $.get(ALL_VARIANTS_URL + '?productId=' + productId)
        .done((res) => {
            productVariants = res;

            PopulateOptionsLength(res);

            if (productVariants.length > 1) {
                if ($VARIANT_OPTION_1_ELEMENTS) {
                    $VARIANT_OPTION_1_ELEMENTS.on('click', function (e) {
                        let $self = $(this);

                        // choose the option 1
                        option1 = $self.val();

                        // debugging
                        printOption();

                        // find variant with chosen option 1
                        currentlySelectedVariants = productVariants.filter(x => x.Option1.toLowerCase() == option1.toLowerCase());

                        console.table(currentlySelectedVariants);

                        if (currentlySelectedVariants.length == 1) {
                            // chose the variant
                            chosenVariant = currentlySelectedVariants[0].Id;

                            if (IsVariantAvailable(currentlySelectedVariants[0])) {

                                // chosing the second and third option
                                const opt2 = currentlySelectedVariants[0].Option2
                                const opt3 = currentlySelectedVariants[0].Option3

                                // radio
                                $VARIANT_OPTION_2_ELEMENTS.prop('disabled', true);
                                $VARIANT_OPTION_3_ELEMENTS.prop('disabled', true);

                                $VARIANT_OPTION_2_ELEMENTS_WITH_OPT(opt2).prop('disabled', false).prop('checked', true);
                                $VARIANT_OPTION_3_ELEMENTS_WITH_OPT(opt3).prop('disabled', false).prop('checked', true);

                                // select
                                $VARIANT_OPTION_2_SELECT_ELEMENTS.children('option').prop('disabled', true);
                                $VARIANT_OPTION_3_SELECT_ELEMENTS.children('option').prop('disabled', true);

                                $VARIANT_OPTION_2_SELECT_ELEMENTS.children(`option[value="${opt2}"]`).prop('disabled', false);
                                $VARIANT_OPTION_3_SELECT_ELEMENTS.children(`option[value="${opt3}"]`).prop('disabled', false);
                                $VARIANT_OPTION_2_SELECT_ELEMENTS.val(opt2);
                                $VARIANT_OPTION_3_SELECT_ELEMENTS.val(opt3);

                                EnableAddToCartElement();
                            }
                            else {
                                // radio
                                $VARIANT_OPTION_2_ELEMENTS.prop('disabled', true);
                                $VARIANT_OPTION_3_ELEMENTS.prop('disabled', true);

                                // select
                                $VARIANT_OPTION_2_SELECT_ELEMENTS.children('option').prop('disabled', true);
                                $VARIANT_OPTION_3_SELECT_ELEMENTS.children('option').prop('disabled', true);

                                DisableAddToCartElement();
                            }
                        }
                        else if (currentlySelectedVariants.length > 1) {
                            // reset option
                            if (optionTwoLength > 1) {
                                $VARIANT_OPTION_2_ELEMENTS.prop('checked', false).prop('disabled', false);

                                // select
                                $VARIANT_OPTION_2_SELECT_ELEMENTS.val('');
                                $VARIANT_OPTION_2_SELECT_ELEMENTS.children('option').prop('disabled', false);

                                ResetOption('option2');
                            }
                            if (optionThreeLength > 1) {
                                $VARIANT_OPTION_3_ELEMENTS.prop('checked', false).prop('disabled', false);

                                // select
                                $VARIANT_OPTION_3_SELECT_ELEMENTS.val('');
                                $VARIANT_OPTION_3_SELECT_ELEMENTS.children('option').prop('disabled', false);

                                ResetOption('option3');
                            }


                            // collectiong the unavailable variants
                            const notAvailableVariants = []
                            const notAvailableOpt2 = [];
                            const notAvailableOpt3 = [];

                            currentlySelectedVariants.forEach(v => {
                                if (!IsVariantAvailable(v)) {
                                    notAvailableVariants.push(v);

                                    if (notAvailableOpt2.findIndex(x => x == v.Option2) == -1) {
                                        notAvailableOpt2.push(v.Option2);
                                    }
                                    if (notAvailableOpt3.findIndex(x => x == v.Option3) == -1) {
                                        notAvailableOpt3.push(v.Option3);
                                    }
                                }
                            });

                            notAvailableOpt2.forEach(v => {

                                if (notAvailableVariants.filter(x => x.Option2 == v).length == currentlySelectedVariants.filter(x => x.Option2 == v).length) {
                                    $VARIANT_OPTION_2_ELEMENTS_WITH_OPT(v).prop('disabled', true);
                                    $VARIANT_OPTION_2_SELECT_ELEMENTS.children(`option[value="${v}"]`).prop('disabled', true);
                                }

                            });

                            notAvailableOpt3.forEach(v => {

                                if (notAvailableVariants.filter(x => x.Option3 == v).length == currentlySelectedVariants.filter(x => x.Option3 == v).length) {
                                    $VARIANT_OPTION_3_ELEMENTS_WITH_OPT(v).prop('disabled', true);
                                    $VARIANT_OPTION_3_SELECT_ELEMENTS.children(`option[value="${v}"]`).prop('disabled', true);
                                }

                            });

                            // collecting the unlisted second variants
                            $VARIANT_OPTION_2_ELEMENTS.each(function () {
                                // option value
                                const opt2 = $(this).val();

                                if (currentlySelectedVariants.findIndex(x => x.Option2 == opt2) == -1) {
                                    $VARIANT_OPTION_2_ELEMENTS_WITH_OPT(opt2).prop('disabled', true);
                                    $VARIANT_OPTION_2_SELECT_ELEMENTS.children(`option[value="${opt2}"]`).prop('disabled', true);
                                }

                            });

                            $VARIANT_OPTION_2_SELECT_ELEMENTS.children(`option`).each(function () {
                                // option value
                                const opt2 = $(this).val();

                                if (currentlySelectedVariants.findIndex(x => x.Option2 == opt2) == -1) {
                                    $VARIANT_OPTION_2_ELEMENTS_WITH_OPT(opt2).prop('disabled', true);
                                    $VARIANT_OPTION_2_SELECT_ELEMENTS.children(`option[value="${opt2}"]`).prop('disabled', true);
                                }
                            });


                            if (_.difference(currentlySelectedVariants, notAvailableVariants).length == 1) {
                                const variant = _.difference(currentlySelectedVariants, notAvailableVariants)[0];

                                chosenVariant = variant.Id;

                                // radio
                                $VARIANT_OPTION_2_ELEMENTS_WITH_OPT(variant.Option2).prop('checked', true);
                                $VARIANT_OPTION_3_ELEMENTS_WITH_OPT(variant.Option3).prop('checked', true);

                                // select
                                $VARIANT_OPTION_2_SELECT_ELEMENTS.val(variant.Option2);
                                $VARIANT_OPTION_3_SELECT_ELEMENTS.val(variant.Option3);

                                EnableAddToCartElement();
                            }
                            else {
                                chosenVariant = null;
                                DisableAddToCartElement();
                            }
                        }
                        else {
                            DisableAddToCartElement();
                        }
                    });
                }

                if ($VARIANT_OPTION_1_SELECT_ELEMENTS) {
                    $VARIANT_OPTION_1_SELECT_ELEMENTS.on('change', function () {
                        let $self = $(this);

                        // choose the option 1
                        option1 = $self.val();

                        // debugging
                        printOption();

                        // find variant with chosen option 1
                        currentlySelectedVariants = productVariants.filter(x => x.Option1.toLowerCase() == option1.toLowerCase());

                        console.table(currentlySelectedVariants);

                        if (currentlySelectedVariants.length == 1) {
                            if (IsVariantAvailable(currentlySelectedVariants[0])) {
                                // chose the variant
                                chosenVariant = currentlySelectedVariants[0].Id;

                                // chosing the second and third option
                                const opt2 = currentlySelectedVariants[0].Option2
                                const opt3 = currentlySelectedVariants[0].Option3

                                // radio
                                $VARIANT_OPTION_2_ELEMENTS.prop('disabled', true);
                                $VARIANT_OPTION_3_ELEMENTS.prop('disabled', true);

                                $VARIANT_OPTION_2_ELEMENTS_WITH_OPT(opt2).prop('disabled', false).prop('checked', true);
                                $VARIANT_OPTION_3_ELEMENTS_WITH_OPT(opt3).prop('disabled', false).prop('checked', true);

                                // select
                                $VARIANT_OPTION_2_SELECT_ELEMENTS.children('option').prop('disabled', true);
                                $VARIANT_OPTION_3_SELECT_ELEMENTS.children('option').prop('disabled', true);

                                $VARIANT_OPTION_2_SELECT_ELEMENTS.children(`option[value="${opt2}"]`).prop('disabled', false);
                                $VARIANT_OPTION_3_SELECT_ELEMENTS.children(`option[value="${opt3}"]`).prop('disabled', false);
                                $VARIANT_OPTION_2_SELECT_ELEMENTS.val(opt2);
                                $VARIANT_OPTION_3_SELECT_ELEMENTS.val(opt3);

                                EnableAddToCartElement();
                            }
                            else {
                                // radio
                                $VARIANT_OPTION_2_ELEMENTS.prop('disabled', true);
                                $VARIANT_OPTION_3_ELEMENTS.prop('disabled', true);

                                // select
                                $VARIANT_OPTION_2_SELECT_ELEMENTS.children('option').prop('disabled', true);
                                $VARIANT_OPTION_3_SELECT_ELEMENTS.children('option').prop('disabled', true);

                                DisableAddToCartElement();
                            }
                        }
                        else if (currentlySelectedVariants.length > 1) {
                            // reset option
                            if (optionTwoLength > 1) {
                                $VARIANT_OPTION_2_ELEMENTS.prop('checked', false).prop('disabled', false);

                                // select
                                $VARIANT_OPTION_2_SELECT_ELEMENTS.val('');
                                $VARIANT_OPTION_2_SELECT_ELEMENTS.children('option').prop('disabled', false);

                                ResetOption('option2');
                            }
                            if (optionThreeLength > 1) {
                                $VARIANT_OPTION_3_ELEMENTS.prop('checked', false).prop('disabled', false);

                                // select
                                $VARIANT_OPTION_3_SELECT_ELEMENTS.val('');
                                $VARIANT_OPTION_3_SELECT_ELEMENTS.children('option').prop('disabled', false);

                                ResetOption('option3');
                            }


                            // collectiong the unavailable variants
                            const notAvailableVariants = []
                            const notAvailableOpt2 = [];
                            const notAvailableOpt3 = [];

                            currentlySelectedVariants.forEach(v => {
                                if (!IsVariantAvailable(v)) {
                                    notAvailableVariants.push(v);

                                    if (notAvailableOpt2.findIndex(x => x == v.Option2) == -1) {
                                        notAvailableOpt2.push(v.Option2);
                                    }
                                    if (notAvailableOpt3.findIndex(x => x == v.Option3) == -1) {
                                        notAvailableOpt3.push(v.Option3);
                                    }
                                }
                            });

                            notAvailableOpt2.forEach(v => {

                                if (notAvailableVariants.filter(x => x.Option2 == v).length == currentlySelectedVariants.filter(x => x.Option2 == v).length) {
                                    $VARIANT_OPTION_2_ELEMENTS_WITH_OPT(v).prop('disabled', true);
                                    $VARIANT_OPTION_2_SELECT_ELEMENTS.children(`option[value="${v}"]`).prop('disabled', true);
                                }

                            });

                            notAvailableOpt3.forEach(v => {

                                if (notAvailableVariants.filter(x => x.Option3 == v).length == currentlySelectedVariants.filter(x => x.Option3 == v).length) {
                                    $VARIANT_OPTION_3_ELEMENTS_WITH_OPT(v).prop('disabled', true);
                                    $VARIANT_OPTION_3_SELECT_ELEMENTS.children(`option[value="${v}"]`).prop('disabled', true);
                                }

                            });

                            // collecting the unlisted second variants
                            $VARIANT_OPTION_2_ELEMENTS.each(function () {
                                // option value
                                const opt2 = $(this).val();

                                if (currentlySelectedVariants.findIndex(x => x.Option2 == opt2) == -1) {
                                    $VARIANT_OPTION_2_ELEMENTS_WITH_OPT(opt2).prop('disabled', true);
                                    $VARIANT_OPTION_2_SELECT_ELEMENTS.children(`option[value="${opt2}"]`).prop('disabled', true);
                                }

                            });

                            $VARIANT_OPTION_2_SELECT_ELEMENTS.children(`option`).each(function () {
                                // option value
                                const opt2 = $(this).val();

                                if (currentlySelectedVariants.findIndex(x => x.Option2 == opt2) == -1) {
                                    $VARIANT_OPTION_2_ELEMENTS_WITH_OPT(opt2).prop('disabled', true);
                                    $VARIANT_OPTION_2_SELECT_ELEMENTS.children(`option[value="${opt2}"]`).prop('disabled', true);
                                }
                            });


                            if (_.difference(currentlySelectedVariants, notAvailableVariants).length == 1) {
                                const variant = _.difference(currentlySelectedVariants, notAvailableVariants)[0];

                                chosenVariant = variant.Id;

                                // radio
                                $VARIANT_OPTION_2_ELEMENTS_WITH_OPT(variant.Option2).prop('checked', true);
                                $VARIANT_OPTION_3_ELEMENTS_WITH_OPT(variant.Option3).prop('checked', true);

                                // select
                                $VARIANT_OPTION_2_SELECT_ELEMENTS.val(variant.Option2);
                                $VARIANT_OPTION_3_SELECT_ELEMENTS.val(variant.Option3);

                                EnableAddToCartElement();
                            }
                            else {
                                DisableAddToCartElement();
                            }
                        }
                        else {
                            DisableAddToCartElement();
                        }
                    });
                }

                if ($VARIANT_OPTION_2_ELEMENTS) {
                    $VARIANT_OPTION_2_ELEMENTS.on('click', function (e) {

                        let $self = $(this);

                        // choose the option 1
                        option2 = $self.val();

                        // debugging
                        printOption();


                        // check whether option 1 has been selected
                        if (option1 == undefined) {
                            // @ts-ignore
                            Notify('Please choose the first option!')
                            return;
                        }
                        else {

                            // find variant with chosen option 1
                            currentlySelectedVariants = productVariants.filter(x => x.Option1.toLowerCase() == option1.toLowerCase() && x.Option2.toLowerCase() == option2.toLowerCase());

                            console.table(currentlySelectedVariants);

                            if (currentlySelectedVariants.length == 1) {

                                if (IsVariantAvailable(currentlySelectedVariants[0])) {
                                    // chose the variant
                                    chosenVariant = currentlySelectedVariants[0].Id;

                                    // chosing the second and third option
                                    const opt3 = currentlySelectedVariants[0].Option3

                                    $VARIANT_OPTION_3_ELEMENTS.prop('disabled', true);

                                    $VARIANT_OPTION_3_ELEMENTS_WITH_OPT(opt3).prop('disabled', false).prop('checked', true);

                                    // select
                                    $VARIANT_OPTION_3_SELECT_ELEMENTS.children('option').prop('disabled', true);

                                    $VARIANT_OPTION_3_SELECT_ELEMENTS.children(`option[value="${opt3}"]`).prop('disabled', false);
                                    $VARIANT_OPTION_3_SELECT_ELEMENTS.val(opt3);

                                    EnableAddToCartElement();
                                }
                                else {
                                    $VARIANT_OPTION_3_ELEMENTS.prop('disabled', true);

                                    // select
                                    $VARIANT_OPTION_3_SELECT_ELEMENTS.children('option').prop('disabled', true);

                                    DisableAddToCartElement();
                                }
                            }
                            else if (currentlySelectedVariants.length > 1) {
                                // reset option
                                if (optionThreeLength > 1) {
                                    // radio
                                    $VARIANT_OPTION_3_ELEMENTS.prop('checked', false).prop('disabled', false);

                                    // select
                                    $VARIANT_OPTION_3_SELECT_ELEMENTS.val('');
                                    $VARIANT_OPTION_3_SELECT_ELEMENTS.children('option').prop('disabled', false);

                                    ResetOption('option3');
                                }

                                // collectiong the unavailable variants
                                const notAvailableVariants = []
                                const notAvailableOpt3 = [];

                                currentlySelectedVariants.forEach(v => {
                                    if (!IsVariantAvailable(v)) {
                                        notAvailableVariants.push(v);

                                        if (notAvailableOpt3.findIndex(x => x == v.Option3) == -1) {
                                            notAvailableOpt3.push(v.Option3);
                                        }
                                    }
                                });

                                notAvailableOpt3.forEach(v => {

                                    if (notAvailableVariants.filter(x => x.Option3 == v).length == currentlySelectedVariants.filter(x => x.Option3 == v).length) {
                                        $VARIANT_OPTION_3_ELEMENTS_WITH_OPT(v).prop('disabled', true);
                                        $VARIANT_OPTION_3_SELECT_ELEMENTS.children(`option[value="${v}"]`).prop('disabled', true);
                                    }

                                });


                                // collecting the unlisted third variants
                                $VARIANT_OPTION_3_ELEMENTS.each(function () {
                                    // option value
                                    const opt3 = $(this).val();

                                    if (currentlySelectedVariants.findIndex(x => x.Option3 == opt3) == -1) {
                                        $VARIANT_OPTION_3_ELEMENTS_WITH_OPT(opt3).prop('disabled', true);
                                        $VARIANT_OPTION_3_SELECT_ELEMENTS.children(`option[value="${opt3}"]`).prop('disabled', true);
                                    }
                                });

                                $VARIANT_OPTION_3_SELECT_ELEMENTS.children(`option`).each(function () {
                                    // option value
                                    const opt3 = $(this).val();

                                    if (currentlySelectedVariants.findIndex(x => x.Option3 == opt3) == -1) {
                                        $VARIANT_OPTION_3_ELEMENTS_WITH_OPT(opt3).prop('disabled', true);
                                        $VARIANT_OPTION_3_SELECT_ELEMENTS.children(`option[value="${opt3}"]`).prop('disabled', true);
                                    }
                                });

                                if (_.difference(currentlySelectedVariants, notAvailableVariants).length == 1) {
                                    const variant = _.difference(currentlySelectedVariants, notAvailableVariants)[0];

                                    chosenVariant = variant.Id;

                                    // radio
                                    $VARIANT_OPTION_3_ELEMENTS_WITH_OPT(variant.Option3).prop('checked', true);

                                    // select
                                    $VARIANT_OPTION_3_SELECT_ELEMENTS.val(variant.Option3);

                                    EnableAddToCartElement();
                                }
                                else {
                                    chosenVariant = null;
                                    DisableAddToCartElement();
                                }
                            }
                            else {
                                DisableAddToCartElement();
                            }
                        }

                    });
                }

                if ($VARIANT_OPTION_2_SELECT_ELEMENTS) {
                    $VARIANT_OPTION_2_SELECT_ELEMENTS.on('change', function (e) {

                        let $self = $(this);

                        // choose the option 1
                        option2 = $self.val();

                        // debugging
                        printOption();


                        // check whether option 1 has been selected
                        if (option1 == undefined) {
                            // @ts-ignore
                            Notify('Please choose the first option!')
                            return;
                        }
                        else {

                            // find variant with chosen option 1
                            currentlySelectedVariants = productVariants.filter(x => x.Option1.toLowerCase() == option1.toLowerCase() && x.Option2.toLowerCase() == option2.toLowerCase());

                            console.table(currentlySelectedVariants);

                            if (currentlySelectedVariants.length == 1) {

                                if (IsVariantAvailable(currentlySelectedVariants[0])) {
                                    // chose the variant
                                    chosenVariant = currentlySelectedVariants[0].Id;

                                    // chosing the second and third option
                                    const opt3 = currentlySelectedVariants[0].Option3

                                    $VARIANT_OPTION_3_ELEMENTS.prop('disabled', true);

                                    $VARIANT_OPTION_3_ELEMENTS_WITH_OPT(opt3).prop('disabled', false).prop('checked', true);

                                    // select
                                    $VARIANT_OPTION_3_SELECT_ELEMENTS.children('option').prop('disabled', true);

                                    $VARIANT_OPTION_3_SELECT_ELEMENTS.children(`option[value="${opt3}"]`).prop('disabled', false);
                                    $VARIANT_OPTION_3_SELECT_ELEMENTS.val(opt3);

                                    EnableAddToCartElement();
                                }
                                else {
                                    $VARIANT_OPTION_3_ELEMENTS.prop('disabled', true);

                                    // select
                                    $VARIANT_OPTION_3_SELECT_ELEMENTS.children('option').prop('disabled', true);

                                    DisableAddToCartElement();
                                }
                            }
                            else if (currentlySelectedVariants.length > 1) {
                                // reset option
                                if (optionThreeLength > 1) {
                                    // radio
                                    $VARIANT_OPTION_3_ELEMENTS.prop('checked', false).prop('disabled', false);

                                    // select
                                    $VARIANT_OPTION_3_SELECT_ELEMENTS.val('');
                                    $VARIANT_OPTION_3_SELECT_ELEMENTS.children('option').prop('disabled', false);

                                    ResetOption('option3');
                                }

                                // collectiong the unavailable variants
                                const notAvailableVariants = []
                                const notAvailableOpt3 = [];

                                currentlySelectedVariants.forEach(v => {
                                    if (!IsVariantAvailable(v)) {
                                        notAvailableVariants.push(v);

                                        if (notAvailableOpt3.findIndex(x => x == v.Option3) == -1) {
                                            notAvailableOpt3.push(v.Option3);
                                        }
                                    }
                                });

                                notAvailableOpt3.forEach(v => {

                                    if (notAvailableVariants.filter(x => x.Option3 == v).length == currentlySelectedVariants.filter(x => x.Option3 == v).length) {
                                        $VARIANT_OPTION_3_ELEMENTS_WITH_OPT(v).prop('disabled', true);
                                        $VARIANT_OPTION_3_SELECT_ELEMENTS.children(`option[value="${v}"]`).prop('disabled', true);
                                    }

                                });


                                // collecting the unlisted third variants
                                $VARIANT_OPTION_3_ELEMENTS.each(function () {
                                    // option value
                                    const opt3 = $(this).val();

                                    if (currentlySelectedVariants.findIndex(x => x.Option3 == opt3) == -1) {
                                        $VARIANT_OPTION_3_ELEMENTS_WITH_OPT(opt3).prop('disabled', true);
                                        $VARIANT_OPTION_3_SELECT_ELEMENTS.children(`option[value="${opt3}"]`).prop('disabled', true);
                                    }
                                });

                                $VARIANT_OPTION_3_SELECT_ELEMENTS.children(`option`).each(function () {
                                    // option value
                                    const opt3 = $(this).val();

                                    if (currentlySelectedVariants.findIndex(x => x.Option3 == opt3) == -1) {
                                        $VARIANT_OPTION_3_ELEMENTS_WITH_OPT(opt3).prop('disabled', true);
                                        $VARIANT_OPTION_3_SELECT_ELEMENTS.children(`option[value="${opt3}"]`).prop('disabled', true);
                                    }
                                });

                                if (_.difference(currentlySelectedVariants, notAvailableVariants).length == 1) {
                                    const variant = _.difference(currentlySelectedVariants, notAvailableVariants)[0];

                                    chosenVariant = variant.Id;

                                    // radio
                                    $VARIANT_OPTION_3_ELEMENTS_WITH_OPT(variant.Option3).prop('checked', true);

                                    // select
                                    $VARIANT_OPTION_3_SELECT_ELEMENTS.val(variant.Option3);

                                    EnableAddToCartElement();
                                }
                                else {
                                    DisableAddToCartElement();
                                }
                            }
                            else {
                                DisableAddToCartElement();
                            }
                        }
                    });
                }

                if ($VARIANT_OPTION_3_ELEMENTS) {
                    $VARIANT_OPTION_3_ELEMENTS.on('click', function (e) {
                        let $self = $(this);

                        // choose the option 1
                        option3 = $self.val();

                        // debugging
                        printOption();


                        // check whether option 1 has been selected
                        if (option1 == undefined) {
                            // @ts-ignore
                            Notify('Please choose the first option!')
                            return;
                        }
                        else if (option2 == undefined) {
                            // @ts-ignore
                            Notify('Please choose the second option!')
                            return;
                        }
                        else {

                            // find variant with chosen option 1
                            currentlySelectedVariants = productVariants.filter(x => x.Option1.toLowerCase() == option1.toLowerCase() && x.Option2.toLowerCase() == option2.toLowerCase() && x.Option3.toLowerCase() == option3.toLowerCase());

                            console.table(currentlySelectedVariants);

                            if (currentlySelectedVariants.length == 1) {

                                if (IsVariantAvailable(currentlySelectedVariants[0])) {
                                    // chose the variant
                                    chosenVariant = currentlySelectedVariants[0].Id;

                                    EnableAddToCartElement();
                                }
                                else {
                                    DisableAddToCartElement();
                                }
                            }
                            else {
                                DisableAddToCartElement();
                            }


                            UTILS.UPDATE_PRICE_VIEW_FROM_VARIANT(currentlySelectedVariants[0], $('[data-identity="product-price"]'), $('[data-identity="product-discount"]'));
                        }
                    });
                }

                if ($VARIANT_OPTION_3_SELECT_ELEMENTS) {
                    $VARIANT_OPTION_3_SELECT_ELEMENTS.on('change', function (e) {
                        let $self = $(this);

                        // choose the option 1
                        option3 = $self.val();

                        // debugging
                        printOption();


                        // check whether option 1 has been selected
                        if (option1 == undefined) {
                            // @ts-ignore
                            Notify('Please choose the first option!')
                            return;
                        }
                        else if (option2 == undefined) {
                            // @ts-ignore
                            Notify('Please choose the second option!')
                            return;
                        }
                        else {

                            // find variant with chosen option 1
                            currentlySelectedVariants = productVariants.filter(x => x.Option1.toLowerCase() == option1.toLowerCase() && x.Option2.toLowerCase() == option2.toLowerCase() && x.Option3.toLowerCase() == option3.toLowerCase());

                            console.table(currentlySelectedVariants);

                            if (currentlySelectedVariants.length == 1) {

                                if (IsVariantAvailable(currentlySelectedVariants[0])) {
                                    // chose the variant
                                    chosenVariant = currentlySelectedVariants[0].Id;

                                    EnableAddToCartElement();
                                }
                                else {
                                    DisableAddToCartElement();
                                }
                            }
                            else {
                                DisableAddToCartElement();
                            }


                            UTILS.UPDATE_PRICE_VIEW_FROM_VARIANT(currentlySelectedVariants[0], $('[data-identity="product-price"]'), $('[data-identity="product-discount"]'));
                        }

                    });
                }
            }
            else {
                currentlySelectedVariants = productVariants[0];

                // @ts-ignore
                chosenVariant = currentlySelectedVariants.Id;

                // @ts-ignore
                if (IsVariantAvailable(currentlySelectedVariants)) {

                    EnableAddToCartElement();

                }

            }

        });

    $ADD_TO_CART_ELEMENTS.on('click', function () {

        console.log("add to cart");

        let $self = $(this);

        // if the button is disabled
        if ($self.hasClass('disabled')) {
            if (chosenVariant !== undefined) {
                // @ts-ignore
                Notify(STOCK_EMPTY_VARIANT_MESSAGE, 'error');
            }
            else {
                // @ts-ignore
                Notify(CHOOSE_VARIANT_MESSAGE, 'error');
            }
        }
        else {

            // get the product id
            let productId = $self.data('productId');
            let autoAddress = $self.data('auto-address');

            AddToCart(productId, chosenVariant, itemQuantity, autoAddress)
                .then(res => {
                    // notify
                    // @ts-ignore
                    Notify(SUCCESS_ADD_MESSAGE, 'success');

                    // update the quantity
                    $CART_QUANTITY_ELEMENTS().text(res.data.quantity);

                    // if sidecart was empty
                    if ($('.bw-empty-cart').length) {
                        $SIDECART_ITEM_CONTAINER().empty();
                    }

                    // if item was exist on cart
                    // update the sidecart view
                    if ($SIDECART_ITEM_CONTAINER().find(`[data-identity="cart-item"][data-id="${res.data.item.ID}"]`).length) {
                        $SIDECART_ITEM_CONTAINER().find(`[data-identity="cart-item"][data-id="${res.data.item.ID}"]`).first().remove();
                    }

                    // appending the new item into sidecartview
                    $SIDECART_ITEM_CONTAINER().append(UTILS.CART_ITEM_ELEMENT_STRING(res.data.item));

                    // add delete cart item handler
                    $CART_ITEM_DELETE_ACTION_ELEMENT()
                        .off()
                        .click(function () {
                            let id = $(this).data('id');
                            let isUnique = $(this).data('isUnique');
                            let prompt = $(this).data('prompt');

                            let itemInfo = {
                                imageUrl: $(`[data-identity="cart-item-image"][data-id="${id}"]`).data('imageUrl'),
                                productName: $(`[data-identity="cart-item-name"][data-id="${id}"]`).data('name')
                            };

                            console.log(itemInfo);

                            if (prompt) {
                                // @ts-ignore
                                window[prompt].call(this, itemInfo)
                                    .then(res => {

                                        if (res) {
                                            if (isUnique != undefined && isUnique != null) {
                                                DeleteCartItem(id)
                                            }
                                            else {
                                                DeleteCartItem(id, isUnique);
                                            }
                                        }

                                    })
                            }

                        });

                    // add update cart item handler
                    $CART_ITEM_QUANTITY_INPUT()
                        .off('change')
                        .on('change', function () {
                            // logging
                            console.log('updating...');

                            // get the quantity
                            let qty = $(this).val();

                            if (qty <= 0) {
                                $(this).val(1);

                                // cancel the operation
                                return;
                            }

                            // get the cart id
                            let id = $(this).data('id');

                            // get unique
                            let isUnique = $(this).data('isUnique');

                            if (isUnique) {
                                UpdateCartItem(id, qty, isUnique);
                            }
                            else {
                                UpdateCartItem(id, qty);
                            }
                        });

                    // calculate subtotal cart
                    CalculateCart();

                    // clear selections
                    ResetChoices();

                    // unload spinner
                    UnloadSpinnerAddToCartElement();

                    // enable all variant options;
                    EnableAllVariantOptions();

                    // redisabled the add to cart button
                    if (productVariants.length > 1) {
                        DisableAddToCartElement();
                    }
                    else {
                        // @ts-ignore
                        chosenVariant = productVariants[0].Id;

                        EnableAddToCartElement();
                    }

                    //Redirect
                    var redirect = $self.attr('redirect');

                    if (redirect != undefined && redirect != "") {
                        window.location.href = redirect;
                    }


                    //// automatic add
                    //// product id
                    //const autoProductId = 4022;

                    //console.log('auto add');

                    //var items = $CART_ITEMS();

                    //var getId = $(`[data-identity="cart-item"][data-variant-id="${'7eb2ad81-d448-4bbf-5a2d-8c707c523821'}"]`);

                    //if (getId.length === 0) {

                    //    // variant id
                    //    const autoVariantId = '7EB2AD81-D448-4BBF-5A2D-8C707C523821';

                    //    // qty
                    //    const autoQty = 1;

                    //    AddToCart(autoProductId, autoVariantId, autoQty)
                    //        .then(r => {

                    //        })
                    //        .catch(c => {

                    //        });
                    //}
                })
                .catch(err => {
                    if (err.response.status == 400) {
                        // @ts-ignore
                        Notify(err.response.statusText, 'error');
                    }
                    else {
                        console.error('Error code: ' + err.response.status);
                        // @ts-ignore
                        Notify(FAIL_ADD_MESSAGE, 'error');
                    }
                });
        }
    });
}

/*
 * =============================
 * Update Cart Item
 * =============================
 */
if ($CART_ITEM_QUANTITY_INPUT().length) {
    $CART_ITEM_QUANTITY_INPUT()
        .off('change')
        .on('change', function () {
            // logging
            console.log('updating...');

            // get the quantity
            let qty = $(this).val();

            if (qty <= 0) {
                $(this).val(1);

                // cancel the operation
                return;
            }

            // get the cart id
            let id = $(this).data('id');

            // get unique
            let isUnique = $(this).data('isUnique');

            if (isUnique) {
                UpdateCartItem(id, qty, isUnique);
            }
            else {
                UpdateCartItem(id, qty);
            }
        });
}
/*
 * =============================
 * Update Cart Item .\END
 * =============================
 */

if ($SHIPPING_ADDRESS_SUBMIT_BUTTON.length) {
    $SHIPPING_ADDRESS_SUBMIT_BUTTON.on('click', function () {
        let chosenAddress = $('input[name="shippingAddress"]:checked').val();

        console.log(chosenAddress);

        $.post('/umbraco/surface/cart/addshipmentaddressajax', { addressId: chosenAddress })
            .done(res => {
                window.location.reload();
            })
            .fail(err => {
                // @ts-ignore
                Toaster({
                    text: err.statusText,
                    duration: 5000,
                    backgroundColor: TOASTER_ERROR_BG_COLOR,
                    position: 'right',
                    gravity: 'bottom'
                });

                console.error(err);
            });
    });
}

function OnBeginAddDeliveryCourier() {
    $DELIVERY_COURIER_SUBMIT_BUTTON
        .prop('disabled', true)
        .addClass('disabled');
}

// expose it to global.
// @ts-ignore
window.OnBeginAddDeliveryCourier = OnBeginAddDeliveryCourier;

function OnFailureAddDeliveryCourier(err) {
    $DELIVERY_COURIER_SUBMIT_BUTTON
        .prop('disabled', false)
        .removeClass('disabled');

    console.log(err);

    if (err.status == 500) {
        // @ts-ignore
        Toaster({
            text: DELIVERY_COURIER_ERROR_MESSAGE,
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR,
            position: 'right',
            gravity: 'bottom'
        });
    }
    else {
        // @ts-ignore
        Toaster({
            text: err.statusText,
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR,
            position: 'right',
            gravity: 'bottom'
        });
    }
}

// expose it to global.
// @ts-ignore
window.OnFailureAddDeliveryCourier = OnFailureAddDeliveryCourier;

function OnSuccessAddDeliveryCourier() {
    window.location.reload();
}

// expose it to global.
// @ts-ignore
window.OnSuccessAddDeliveryCourier = OnSuccessAddDeliveryCourier;

if ($DELIVERY_COURIER_RADIO.length) {
    $DELIVERY_COURIER_RADIO.change(function () {
        // get name
        let courierName = $(this).data('name');

        // get service
        let courierService = $(this).data('service');

        // get cost
        let courierCost = $(this).data('price');

        // put the value in
        $DELIVERY_COURIER_NAME_INPUT.val(courierName);

        // put the value in
        $DELIVERY_COURIER_SERVICE_INPUT.val(courierService);

        // put the value in
        $DELIVERY_COURIER_COST_INPUT.val(courierCost);

        console.log($DELIVERY_COURIER_COST_INPUT.val());
    });
}

if ($PAYMENT_RADIO.length) {
    $PAYMENT_RADIO.change(function () {
        // get bank id
        let bankId = $(this).val();

        // get payment type
        let paymentType = $(this).data('paymentType');

        $PAYMENT_BANK_INPUT.val(bankId);

        $PAYMENT_TYPE_INPUT.val(paymentType);

        console.log(bankId + " " + paymentType);
    });
}

function OnBeginAddPaymentAndNote() {
    $PAYMENT_AND_NOTE_SUBMIT_BUTTON
        .prop('disabled', true)
        .addClass('disabled');
}

// expose it to global.
// @ts-ignore
window.OnBeginAddPaymentAndNote = OnBeginAddPaymentAndNote;

function OnFailureAddPaymentAndNote(err) {
    $PAYMENT_AND_NOTE_SUBMIT_BUTTON
        .prop('disabled', false)
        .removeClass('disabled');

    if (err.status == 500) {
        // @ts-ignore
        Toaster({
            text: PAYMENT_AND_NOTE_ERROR_MESSAGE,
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR,
            position: 'right',
            gravity: 'bottom'
        });
    }
    else {
        // @ts-ignore
        Toaster({
            text: err.statusText,
            duration: 5000,
            backgroundColor: TOASTER_ERROR_BG_COLOR,
            position: 'right',
            gravity: 'bottom'
        });
    }
}

// expose it to global.
// @ts-ignore
window.OnFailureAddPaymentAndNote = OnFailureAddPaymentAndNote;

function OnSuccessAddPaymentAndNote(data) {
    console.log('add payment');

    $MASTER_LOADER.addClass('active');
    $PAYMENT_AND_NOTE_SUBMIT_BUTTON.addClass('disabled').prop('disabled', true);

    if (data === 0) {

        $.post(COMPLETE_TRANSACTION_URL)
            .then(res => {

                SendAnalyticsData();
                
                $MASTER_LOADER.removeClass('active');
                window.location.href = '/completed';
            })
            .done(res => {
                SendAnalyticsData();

                $MASTER_LOADER.removeClass('active');
                window.location.href = '/completed';
            })
            .fail(err => {
                // @ts-ignore
                Toaster({
                    text: "Order failed. Please try again later.",
                    duration: 5000,
                    backgroundColor: TOASTER_ERROR_BG_COLOR,
                    position: 'right',
                    gravity: 'bottom'
                });

                $MASTER_LOADER.removeClass('active');
                window.location.reload();

            });
    }
    else if (data === 2 || data === 4) {
        // is direct debit
        const isDirectDebit = data === 4 ? true : false as boolean;

        // process with midtrans
        PayWithMidtrans(isDirectDebit)
            .then(res => {
                const response: MidtransTransactionResponse = res.data as MidtransTransactionResponse;

                const info: PaymentGatewayInfo = {
                    Id: null,
                    InvoiceNumber: response.OrderId,
                    Status: "Pending",
                    PaymentGatewayMethod: PaymentGatewayMethod.Unknown,
                    PaymentGateway: isDirectDebit ? PaymentType.MidtransDirectDebit : PaymentType.Midtrans,
                    PaymentGatewayOrderId: null,
                    Token: response.token,
                    RedirectUri: response.redirect_url
                }

                if (response.token !== null && response.token !== undefined) {

                    if (isDirectDebit) {
                        // complete the transaction
                        CompleteTransaction(response.OrderId)
                            .then(() => {

                                SavePaymentGatewayInfo(info)
                                    .then(() => {
                                        SendAnalyticsData();

                                        window.open(response.redirect_url, '_blank');
                                        window.location.href = '/completed';
                                    })
                                    .catch(err => {
                                        console.error(err);
                                    });
                            });
                    }
                    else {
                        // create callbacks
                        const callbacks: SnapCallback = {
                            successCallback: (result: any = undefined) => {
                                info.PaymentGatewayOrderId = result.transaction_id;
                                console.log(info);

                                CompleteTransaction(response.OrderId)
                                    .then(() => {
                                        SavePaymentGatewayInfo(info)
                                            .then(() => {

                                                SendAnalyticsData();
                                                window.location.href = '/completed';
                                            });

                                    })
                                    .catch(err => {
                                        console.error(err);
                                    });
                            },
                            pendingCallback: (result: any = undefined) => {
                                info.PaymentGatewayOrderId = result.transaction_id;
                                console.log(info);

                                CompleteTransaction(response.OrderId)
                                    .then(() => {
                                        console.log(info);
                                        SavePaymentGatewayInfo(info)
                                            .then(() => {
                                                SendAnalyticsData();
                                                window.location.href = '/completed';
                                            });
                                    })
                                    .catch(err => {
                                        console.error(err);
                                    });
                            },
                            errorCallback: (result: any = undefined) => {
                                // @ts-ignore
                                window.Toaster({
                                    gravity: 'top',
                                    position: 'center',
                                    backgroundColor: TOASTER_ERROR_BG_COLOR,
                                    text: 'There has been an error processing your payment. Please try again later!',
                                    duration: 6000
                                });
                            },
                            closeCallback: (result: any = undefined) => {
                                // @ts-ignore
                                $PAYMENT_AND_NOTE_SUBMIT_BUTTON.removeClass('disabled').prop('disabled', false);
                            }
                        }

                        $MASTER_LOADER.removeClass('active');
                        SnapMidtransModal(response.token, callbacks);
                    }

                }
            })
            .catch(err => {
                console.error(err);

                // @ts-ignore
                Toaster({
                    gravity: 'top',
                    position: 'center',
                    backgroundColor: TOASTER_ERROR_BG_COLOR,
                    text: 'There has been an error processing your payment. Please try again later!',
                    duration: 6000
                })
            });
    }
    else if (data === 3) {
        window.location.reload();
        //$(PAYPAL_BUTTON_CONTAINER_SELECTOR).click();
    }

    
}

// expose it to global.
// @ts-ignore
window.OnSuccessAddPaymentAndNote = OnSuccessAddPaymentAndNote;


// checkout
import {
    CompleteTransaction
} from './checkout';


// conventional bank transfer
if ($COMPLETE_TRANSACTION_BUTTON().length) {
    $COMPLETE_TRANSACTION_BUTTON().on('click', function () {

        $MASTER_LOADER.addClass('active');
        $(this).addClass('disabled').prop('disabled', true);

        $.post(COMPLETE_TRANSACTION_URL)
            .then(res => {
                SendAnalyticsData();
                
                $MASTER_LOADER.removeClass('active');
                window.location.href = '/completed';
            })
            .done(res => {
                SendAnalyticsData();

                $MASTER_LOADER.removeClass('active');
                window.location.href = '/completed';
            })
            .fail(err => {
                // @ts-ignore
                Toaster({
                    text: "Order failed. Please try again later.",
                    duration: 5000,
                    backgroundColor: TOASTER_ERROR_BG_COLOR,
                    position: 'right',
                    gravity: 'bottom'
                });

                $MASTER_LOADER.removeClass('active');
                window.location.reload();

            });

    });
}

// payment gateway
import PaymentGatewayInfo from './interfaces/payment-gateway-info.interface';
import PaymentGatewayMethod from './interfaces/payment-gateway-method.interface';
import PaymentType from './interfaces/payment-type.interface';


// payment gateway - midtrans
import MidtransTransactionResponse from './interfaces/midtrans/midtrans-transaction-response.interface';
import SnapCallback from './interfaces/midtrans/snap-callback.interface';

import {
    PayWithMidtrans,
    SavePaymentGatewayInfo,
    SnapMidtransModal
} from './payment-gateway/midtrans';

if ($PAY_WITH_MIDTRANS_BUTTON().length) {
    $PAY_WITH_MIDTRANS_BUTTON().on('click', function () {

        $(this).addClass('disabled').prop('disabled', true);

        // is direct debit
        const isDirectDebit = $(this).data('isDirectDebit') as boolean;

        // process with midtrans
        PayWithMidtrans(isDirectDebit)
            .then(res => {
                const response: MidtransTransactionResponse = res.data as MidtransTransactionResponse;

                const info: PaymentGatewayInfo = {
                    Id: null,
                    InvoiceNumber: response.OrderId,
                    Status: "Pending",
                    PaymentGatewayMethod: PaymentGatewayMethod.Unknown,
                    PaymentGateway: isDirectDebit ? PaymentType.MidtransDirectDebit : PaymentType.Midtrans,
                    PaymentGatewayOrderId: null,
                    Token: response.token,
                    RedirectUri: response.redirect_url
                }

                if (response.token != null && response.token != undefined) {

                    if (isDirectDebit) {
                        // complete the transaction
                        CompleteTransaction(response.OrderId)
                            .then(() => {

                                SavePaymentGatewayInfo(info)
                                    .then(() => {
                                        SendAnalyticsData();

                                        window.open(response.redirect_url, '_blank');
                                        window.location.href = '/completed';
                                    })
                                    .catch(err => {
                                        console.error(err);
                                    });
                            });
                    }
                    else {
                        // create callbacks
                        const callbacks: SnapCallback = {
                            successCallback: (result: any = undefined) => {
                                info.PaymentGatewayOrderId = result.transaction_id;
                                console.log(info);

                                CompleteTransaction(response.OrderId)
                                    .then(() => {
                                        SavePaymentGatewayInfo(info)
                                            .then(() => {
                                                SendAnalyticsData();
                                                window.location.href = '/completed';
                                            });

                                    })
                                    .catch(err => {
                                        console.error(err);
                                    });
                            },
                            pendingCallback: (result: any = undefined) => {
                                info.PaymentGatewayOrderId = result.transaction_id;
                                console.log(info);

                                CompleteTransaction(response.OrderId)
                                    .then(() => {
                                        console.log(info);
                                        SavePaymentGatewayInfo(info)
                                            .then(() => {
                                                SendAnalyticsData();
                                                window.location.href = '/completed';
                                            });
                                    })
                                    .catch(err => {
                                        console.error(err);
                                    });
                            },
                            errorCallback: (result: any = undefined) => {
                                // @ts-ignore
                                window.Toaster({
                                    gravity: 'top',
                                    position: 'center',
                                    backgroundColor: TOASTER_ERROR_BG_COLOR,
                                    text: 'There has been an error processing your payment. Please try again later!',
                                    duration: 6000
                                });
                            },
                            closeCallback: (result: any = undefined) => {
                                // @ts-ignore
                                $PAY_WITH_MIDTRANS_BUTTON().removeClass('disabled').prop('disabled', false);
                            }
                        }

                        SnapMidtransModal(response.token, callbacks);
                    }

                }
            })
            .catch(err => {
                console.error(err);

                // @ts-ignore
                Toaster({
                    gravity: 'top',
                    position: 'center',
                    backgroundColor: TOASTER_ERROR_BG_COLOR,
                    text: 'There has been an error processing your payment. Please try again later!',
                    duration: 6000
                })
            });
    });
}


// payment gateway - paypal
import {
    PAYPAL_BUTTON_CONTAINER_SELECTOR
} from './constants';
import Paypal from './payment-gateway/paypal';
import PaypalRenderParam from './interfaces/paypal/paypal-render-param';
import PayWithPaypalResponse from './interfaces/paypal/pay-with-paypal-response.interface';

if ($(PAYPAL_BUTTON_CONTAINER_SELECTOR).length) {
    let payWithPaypalResponse: PayWithPaypalResponse = undefined;

    // creating render param
    const renderParam: PaypalRenderParam = {
        htmlSelector: PAYPAL_BUTTON_CONTAINER_SELECTOR,
        createOrderCallback: async function (data, actions) {
            const res = await Paypal.payWithPaypal();

            payWithPaypalResponse = res.data as PayWithPaypalResponse;

            return actions.order.create({
                purchase_units: [{
                    amount: {
                        currency_code: 'USD',
                        value: payWithPaypalResponse.GrandTotal.toString()
                    }
                }]
            });
        },
        onApproveCallback: (data, actions) => {
            return actions.order.capture().then((details) => {
                // get paypal order data
                const paypalOrderId = details.id;
                const paypalOrderStatus = details.status;

                const paymentGatewayInfo: PaymentGatewayInfo = {
                    Id: null,
                    InvoiceNumber: payWithPaypalResponse.OrderId,
                    Status: paypalOrderStatus.toLowerCase(),
                    PaymentGateway: PaymentType.Paypal,
                    PaymentGatewayMethod: PaymentGatewayMethod.PayPal,
                    PaymentGatewayOrderId: paypalOrderId
                }

                console.log(details);

                CompleteTransaction(payWithPaypalResponse.OrderId)
                    .then(() => {
                        SavePaymentGatewayInfo(paymentGatewayInfo)
                            .then(() => {
                                SendAnalyticsData();
                                window.location.href = '/completed';
                            });

                    })
                    .catch(err => {
                        console.error(err);
                    });
            });
        }
    };

    // rendering paypal button
    Paypal.renderPaypalButton(renderParam);
}

//facebook pixel & analytics

function SendAnalyticsData() {

    var getInv = AjaxRequestData(GET_TRANSACTION_URL);


    // @ts-ignore
    var googleid = getInv.googleid;

    // @ts-ignore
    var facebookid = getInv.facebookid;

    // @ts-ignore
    var total = getInv.grandtotal;

    // @ts-ignore
    var currency = getInv.currency;

    console.log('send pixel data');
    //Send Data to analytics & pixel


    if (facebookid !== "") {
        try {
            // @ts-ignore
            fbq('track', 'Purchase', {
                value: total,
                currency: currency
            });
        } catch (e) {
            //
        }

    }

    if (googleid !== "") {
        // @ts-ignore
        var number = getInv.invoicenumber;

        // @ts-ignore
        var store = getInv.store;

        // @ts-ignore
        var tax = getInv.tax;

        // @ts-ignore
        var shippingcost = getInv.shippingcost;

        try {

            
            // @ts-ignore
            $.each(getInv.items, function (key, value) {

                // @ts-ignore
                ga('ec:addProduct', {
                    'id': value.ID,
                    'name': value.Name,
                    //'category': '@Model.Parent.Value("headTitle")',
                    //'brand': product.brand,
                    //'variant': product.variant,
                    'price': value.Price,
                    'quantity': value.Quantity
                });
            });

            // @ts-ignore
            ga('ec:setAction', 'purchase', {
                // @ts-ignore
                id: number,
                affiliation: store,
                revenue: total,
                tax: tax,
                shipping: shippingcost,
                coupon: ''
            });

            // @ts-ignore
            ga('send', 'pageview');

        } catch (e) {
            //
        }
    }
}

// importing others ecommerce
// modules

// wishlist
import './wishlist';

// promo
import './promo/automatic-promo';
import './promo/coupon-code';
import './promo/change-free-item';

// cart
import './cart';

// confirm payment
import './confirm-payment';

// quick view
import './product-quick-view';

//import './product-classification';

// notify
import './notify/notify';
